
var Rive = (function() {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(Rive) {
  Rive = Rive || {};


null;var k;k||(k=typeof Rive !== 'undefined' ? Rive : {});var aa,ca;k.ready=new Promise(function(a,b){aa=a;ca=b});function da(){function a(c){let d=0;b.forEach((e,f)=>{c>e?b.delete(f):d=Math.max(d,f)});return d}console.assert(!0);const b=new Map;this.push=function(c){const d=Date.now();b.set(c,d+1E3);return a(d)}}
k.onRuntimeInitialized=function(){function a(t){this.od=t;this.ke=t.getContext("2d");this.oe=g;this.td=[];this.Dd=0;this.clear=function(){console.assert(0==this.Dd);this.td=[];h.delete(this)};this.save=function(){++this.Dd;this.td.push(g.save.bind(g))};this.restore=function(){0<this.Dd&&(this.td.push(g.restore.bind(g)),--this.Dd)};this.transform=function(v){this.td.push(g.transform.bind(g,v))};this.align=function(v,y,E,L){this.td.push(g.align.bind(g,v,y,E,L))};this.computeAlignment=function(v,y,E,
L,N){this.td.push(g.computeAlignment.bind(g,v,y,E,L,N))};this.flush=function(){console.assert(0==this.Dd);h.add(this);f&&d()}}function b(t){var v={alpha:1,depth:0,stencil:8,antialias:0,premultipliedAlpha:1,preserveDrawingBuffer:0,preferLowPowerToHighPerformance:0,failIfMajorPerformanceCaveat:0,enableExtensionsByDefault:1,explicitSwapControl:0,renderViaOffscreenBackBuffer:0},y=t.getContext("webgl2",v);y||(y=t.getContext("webgl",v));var E=y,L=ea(fa),N={Qe:L,attributes:v,version:v.Re,Td:E};E.canvas&&
(E.canvas.Oe=N);fa[L]=N;("undefined"===typeof v.te||v.te)&&ha(N);l=fa[L];k.qe=q=l&&l.Td;v=n(t.width,t.height);v.me=L;v.od=t;v.be=t.width;v.ae=t.height;v.le=y;return v}function c(t,v){const y=v-1;console.assert(0==(v&y));return t+y&~y}function d(){const t=g.ne;var v=0,y=0,E=0;const L=Array(h.size);var N=0;for(var J of h)J.zd=Math.min(J.od.width,t),J.yd=Math.min(J.od.height,t),J.Ud=J.yd*J.zd,v=Math.max(v,J.zd),y=Math.max(y,J.yd),E+=J.Ud,L[N++]=J;h.clear();if(!(0>=E)){v=1<<(0>=v?0:32-Math.clz32(v-1));
for(y=1<<(0>=y?0:32-Math.clz32(y-1));y*v<E;)v<=y?v*=2:y*=2;v=Math.min(v,t);v=Math.min(y,t);L.sort((ba,qb)=>qb.Ud-ba.Ud);E=new k.DynamicRectanizer(t);for(J=0;J<L.length;){E.reset(v,y);for(N=J;N<L.length;++N){var I=L[N],F=E.addRect(I.zd,I.yd);if(0>F){console.assert(N>J);break}I.Id=F&65535;I.Jd=F>>16}I=c(r.push(E.drawWidth()),Math.min(512,t));F=c(x.push(E.drawHeight()),Math.min(512,t));console.assert(I>=E.drawWidth());console.assert(F>=E.drawHeight());console.assert(I<=t);console.assert(F<=t);g.od.width!=
I&&(g.od.width=I);g.od.height!=F&&(g.od.height=F);g.clear();for(I=J;I<N;++I){F=L[I];g.saveClipRect(F.Id,F.Jd,F.Id+F.zd,F.Jd+F.yd);let ba=new k.Mat2D;ba.xx=F.zd/F.od.width;ba.yy=F.yd/F.od.height;ba.xy=ba.yx=0;ba.tx=F.Id;ba.ty=F.Jd;g.transform(ba);for(const qb of F.td)qb();g.restoreClipRect();F.td=[]}for(g.flush();J<N;++J)I=L[J],F=I.ke,F.globalCompositeOperation="copy",F.drawImage(g.od,I.Id,I.Jd,I.zd,I.yd,0,0,I.od.width,I.od.height);J=N}}}function e(t){const v=u;m=A=0;u=new Map;v.forEach(y=>{try{y(t)}catch(E){console.error(E)}});
d()}const f=navigator.userAgent.match(/firefox|fxios/i);let g=null;const h=new Set,n=k.makeRenderer;k.makeRenderer=function(t,v){return v?(g||(g=b(document.createElement("canvas")),v=g.le,g.ne=Math.min(v.getParameter(v.MAX_RENDERBUFFER_SIZE),v.getParameter(v.MAX_TEXTURE_SIZE))),new a(t)):b(t)};const p=k.Artboard.prototype.draw;k.Artboard.prototype.draw=function(t){t.td?t.td.push(p.bind(this,t.oe)):p.call(this,t)};const r=new da,x=new da;let A=0,m=0,u=new Map;k.requestAnimationFrame=function(t){A||
(A=window.requestAnimationFrame(e));const v=++m;u.set(v,t);return v};k.cancelAnimationFrame=function(t){u.delete(t);A&&0==u.size&&(window.cancelAnimationFrame(A),A=0)};const B=k.WebGLRenderer.prototype.clear;k.WebGLRenderer.prototype.clear=function(){l=fa[this.me];k.qe=q=l&&l.Td;const t=this.od;if(this.be!=t.width||this.ae!=t.height)this.resize(t.width,t.height),this.be=t.width,this.ae=t.height;B.call(this)}};var ia={},ja;for(ja in k)k.hasOwnProperty(ja)&&(ia[ja]=k[ja]);
var ka="./this.program",la="object"===typeof window,ma="function"===typeof importScripts,na="object"===typeof process&&"object"===typeof process.versions&&"string"===typeof process.versions.node,w="",oa,pa,qa,ra,sa;
if(na)w=ma?require("path").dirname(w)+"/":__dirname+"/",oa=function(a,b){ra||(ra=require("fs"));sa||(sa=require("path"));a=sa.normalize(a);return ra.readFileSync(a,b?null:"utf8")},qa=function(a){a=oa(a,!0);a.buffer||(a=new Uint8Array(a));a.buffer||ta("Assertion failed: undefined");return a},pa=function(a,b,c){ra||(ra=require("fs"));sa||(sa=require("path"));a=sa.normalize(a);ra.readFile(a,function(d,e){d?c(d):b(e.buffer)})},1<process.argv.length&&(ka=process.argv[1].replace(/\\/g,"/")),process.argv.slice(2),
process.on("uncaughtException",function(a){throw a;}),process.on("unhandledRejection",function(a){throw a;}),k.inspect=function(){return"[Emscripten Module object]"};else if(la||ma)ma?w=self.location.href:"undefined"!==typeof document&&document.currentScript&&(w=document.currentScript.src),_scriptDir&&(w=_scriptDir),0!==w.indexOf("blob:")?w=w.substr(0,w.replace(/[?#].*/,"").lastIndexOf("/")+1):w="",oa=function(a){var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},ma&&
(qa=function(a){var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),pa=function(a,b,c){var d=new XMLHttpRequest;d.open("GET",a,!0);d.responseType="arraybuffer";d.onload=function(){200==d.status||0==d.status&&d.response?b(d.response):c()};d.onerror=c;d.send(null)};var ua=k.print||console.log.bind(console),va=k.printErr||console.warn.bind(console);for(ja in ia)ia.hasOwnProperty(ja)&&(k[ja]=ia[ja]);ia=null;k.thisProgram&&(ka=k.thisProgram);
var wa=0,xa;k.wasmBinary&&(xa=k.wasmBinary);var noExitRuntime=k.noExitRuntime||!0;"object"!==typeof WebAssembly&&ta("no native wasm support detected");var ya,za=!1,Aa="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function Ba(a,b,c){var d=b+c;for(c=b;a[c]&&!(c>=d);)++c;if(16<c-b&&a.subarray&&Aa)return Aa.decode(a.subarray(b,c));for(d="";b<c;){var e=a[b++];if(e&128){var f=a[b++]&63;if(192==(e&224))d+=String.fromCharCode((e&31)<<6|f);else{var g=a[b++]&63;e=224==(e&240)?(e&15)<<12|f<<6|g:(e&7)<<18|f<<12|g<<6|a[b++]&63;65536>e?d+=String.fromCharCode(e):(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023))}}else d+=String.fromCharCode(e)}return d}function Ca(a,b){return a?Ba(z,a,b):""}
function Da(a,b,c,d){if(!(0<d))return 0;var e=c;d=c+d-1;for(var f=0;f<a.length;++f){var g=a.charCodeAt(f);if(55296<=g&&57343>=g){var h=a.charCodeAt(++f);g=65536+((g&1023)<<10)|h&1023}if(127>=g){if(c>=d)break;b[c++]=g}else{if(2047>=g){if(c+1>=d)break;b[c++]=192|g>>6}else{if(65535>=g){if(c+2>=d)break;b[c++]=224|g>>12}else{if(c+3>=d)break;b[c++]=240|g>>18;b[c++]=128|g>>12&63}b[c++]=128|g>>6&63}b[c++]=128|g&63}}b[c]=0;return c-e}
function Ea(a){for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);55296<=d&&57343>=d&&(d=65536+((d&1023)<<10)|a.charCodeAt(++c)&1023);127>=d?++b:b=2047>=d?b+2:65535>=d?b+3:b+4}return b}var Fa="undefined"!==typeof TextDecoder?new TextDecoder("utf-16le"):void 0;function Ga(a,b){var c=a>>1;for(var d=c+b/2;!(c>=d)&&Ha[c];)++c;c<<=1;if(32<c-a&&Fa)return Fa.decode(z.subarray(a,c));c="";for(d=0;!(d>=b/2);++d){var e=Ia[a+2*d>>1];if(0==e)break;c+=String.fromCharCode(e)}return c}
function Ja(a,b,c){void 0===c&&(c=2147483647);if(2>c)return 0;c-=2;var d=b;c=c<2*a.length?c/2:a.length;for(var e=0;e<c;++e)Ia[b>>1]=a.charCodeAt(e),b+=2;Ia[b>>1]=0;return b-d}function Ka(a){return 2*a.length}function La(a,b){for(var c=0,d="";!(c>=b/4);){var e=C[a+4*c>>2];if(0==e)break;++c;65536<=e?(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023)):d+=String.fromCharCode(e)}return d}
function Ma(a,b,c){void 0===c&&(c=2147483647);if(4>c)return 0;var d=b;c=d+c-4;for(var e=0;e<a.length;++e){var f=a.charCodeAt(e);if(55296<=f&&57343>=f){var g=a.charCodeAt(++e);f=65536+((f&1023)<<10)|g&1023}C[b>>2]=f;b+=4;if(b+4>c)break}C[b>>2]=0;return b-d}function Na(a){for(var b=0,c=0;c<a.length;++c){var d=a.charCodeAt(c);55296<=d&&57343>=d&&++c;b+=4}return b}var Oa,Pa,z,Ia,Ha,C,D,G,Qa;
function Ra(){var a=ya.buffer;Oa=a;k.HEAP8=Pa=new Int8Array(a);k.HEAP16=Ia=new Int16Array(a);k.HEAP32=C=new Int32Array(a);k.HEAPU8=z=new Uint8Array(a);k.HEAPU16=Ha=new Uint16Array(a);k.HEAPU32=D=new Uint32Array(a);k.HEAPF32=G=new Float32Array(a);k.HEAPF64=Qa=new Float64Array(a)}var Sa,Ta=[],Ua=[],Va=[];function Wa(){var a=k.preRun.shift();Ta.unshift(a)}var Xa=0,Ya=null,Za=null;k.preloadedImages={};k.preloadedAudios={};
function ta(a){if(k.onAbort)k.onAbort(a);a="Aborted("+a+")";va(a);za=!0;a=new WebAssembly.RuntimeError(a+". Build with -s ASSERTIONS=1 for more info.");ca(a);throw a;}function $a(){return H.startsWith("data:application/octet-stream;base64,")}var H;H="webgl_advanced.wasm";if(!$a()){var ab=H;H=k.locateFile?k.locateFile(ab,w):w+ab}function bb(){var a=H;try{if(a==H&&xa)return new Uint8Array(xa);if(qa)return qa(a);throw"both async and sync fetching of the wasm failed";}catch(b){ta(b)}}
function cb(){if(!xa&&(la||ma)){if("function"===typeof fetch&&!H.startsWith("file://"))return fetch(H,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+H+"'";return a.arrayBuffer()}).catch(function(){return bb()});if(pa)return new Promise(function(a,b){pa(H,function(c){a(new Uint8Array(c))},b)})}return Promise.resolve().then(function(){return bb()})}
function db(a){for(;0<a.length;){var b=a.shift();if("function"==typeof b)b(k);else{var c=b.Pe;"number"===typeof c?void 0===b.Vd?K(c)():K(c)(b.Vd):c(void 0===b.Vd?null:b.Vd)}}}function K(a){return Sa.get(a)}var eb={};function fb(a){for(;a.length;){var b=a.pop();a.pop()(b)}}function gb(a){return this.fromWireType(D[a>>2])}var hb={},ib={},jb={};function kb(a){if(void 0===a)return"_unknown";a=a.replace(/[^a-zA-Z0-9_]/g,"$");var b=a.charCodeAt(0);return 48<=b&&57>=b?"_"+a:a}
function lb(a,b){a=kb(a);return(new Function("body","return function "+a+'() {\n    "use strict";    return body.apply(this, arguments);\n};\n'))(b)}function mb(a){var b=Error,c=lb(a,function(d){this.name=a;this.message=d;d=Error(d).stack;void 0!==d&&(this.stack=this.toString()+"\n"+d.replace(/^Error(:[^\n]*)?\n/,""))});c.prototype=Object.create(b.prototype);c.prototype.constructor=c;c.prototype.toString=function(){return void 0===this.message?this.name:this.name+": "+this.message};return c}
var nb=void 0;function ob(a){throw new nb(a);}function M(a,b,c){function d(h){h=c(h);h.length!==a.length&&ob("Mismatched type converter count");for(var n=0;n<a.length;++n)pb(a[n],h[n])}a.forEach(function(h){jb[h]=b});var e=Array(b.length),f=[],g=0;b.forEach(function(h,n){ib.hasOwnProperty(h)?e[n]=ib[h]:(f.push(h),hb.hasOwnProperty(h)||(hb[h]=[]),hb[h].push(function(){e[n]=ib[h];++g;g===f.length&&d(e)}))});0===f.length&&d(e)}
function rb(a){switch(a){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError("Unknown type size: "+a);}}var sb=void 0;function O(a){for(var b="";z[a];)b+=sb[z[a++]];return b}var tb=void 0;function P(a){throw new tb(a);}
function pb(a,b,c){c=c||{};if(!("argPackAdvance"in b))throw new TypeError("registerType registeredInstance requires argPackAdvance");var d=b.name;a||P('type "'+d+'" must have a positive integer typeid pointer');if(ib.hasOwnProperty(a)){if(c.Ae)return;P("Cannot register type '"+d+"' twice")}ib[a]=b;delete jb[a];hb.hasOwnProperty(a)&&(b=hb[a],delete hb[a],b.forEach(function(e){e()}))}function ub(a){P(a.jd.md.kd.name+" instance already deleted")}var vb=!1;function wb(){}
function xb(a){--a.count.value;0===a.count.value&&(a.qd?a.sd.vd(a.qd):a.md.kd.vd(a.ld))}function yb(a){if("undefined"===typeof FinalizationGroup)return yb=function(b){return b},a;vb=new FinalizationGroup(function(b){for(var c=b.next();!c.done;c=b.next())c=c.value,c.ld?xb(c):console.warn("object already deleted: "+c.ld)});yb=function(b){vb.register(b,b.jd,b.jd);return b};wb=function(b){vb.unregister(b.jd)};return yb(a)}var zb=void 0,Ab=[];
function Bb(){for(;Ab.length;){var a=Ab.pop();a.jd.Bd=!1;a["delete"]()}}function Cb(){}var Db={};function Eb(a,b,c){if(void 0===a[b].nd){var d=a[b];a[b]=function(){a[b].nd.hasOwnProperty(arguments.length)||P("Function '"+c+"' called with an invalid number of arguments ("+arguments.length+") - expects one of ("+a[b].nd+")!");return a[b].nd[arguments.length].apply(this,arguments)};a[b].nd=[];a[b].nd[d.Kd]=d}}
function Fb(a,b,c){k.hasOwnProperty(a)?((void 0===c||void 0!==k[a].nd&&void 0!==k[a].nd[c])&&P("Cannot register public name '"+a+"' twice"),Eb(k,a,a),k.hasOwnProperty(c)&&P("Cannot register multiple overloads of a function with the same number of arguments ("+c+")!"),k[a].nd[c]=b):(k[a]=b,void 0!==c&&(k[a].Te=c))}function Gb(a,b,c,d,e,f,g,h){this.name=a;this.constructor=b;this.wd=c;this.vd=d;this.ud=e;this.ve=f;this.Hd=g;this.se=h;this.Ee=[]}
function Hb(a,b,c){for(;b!==c;)b.Hd||P("Expected null or instance of "+c.name+", got an instance of "+b.name),a=b.Hd(a),b=b.ud;return a}function Ib(a,b){if(null===b)return this.Xd&&P("null is not a valid "+this.name),0;b.jd||P('Cannot pass "'+Jb(b)+'" as a '+this.name);b.jd.ld||P("Cannot pass deleted object as a pointer of type "+this.name);return Hb(b.jd.ld,b.jd.md.kd,this.kd)}
function Kb(a,b){if(null===b){this.Xd&&P("null is not a valid "+this.name);if(this.Md){var c=this.Yd();null!==a&&a.push(this.vd,c);return c}return 0}b.jd||P('Cannot pass "'+Jb(b)+'" as a '+this.name);b.jd.ld||P("Cannot pass deleted object as a pointer of type "+this.name);!this.Ld&&b.jd.md.Ld&&P("Cannot convert argument of type "+(b.jd.sd?b.jd.sd.name:b.jd.md.name)+" to parameter type "+this.name);c=Hb(b.jd.ld,b.jd.md.kd,this.kd);if(this.Md)switch(void 0===b.jd.qd&&P("Passing raw pointer to smart pointer is illegal"),
this.Je){case 0:b.jd.sd===this?c=b.jd.qd:P("Cannot convert argument of type "+(b.jd.sd?b.jd.sd.name:b.jd.md.name)+" to parameter type "+this.name);break;case 1:c=b.jd.qd;break;case 2:if(b.jd.sd===this)c=b.jd.qd;else{var d=b.clone();c=this.Fe(c,Lb(function(){d["delete"]()}));null!==a&&a.push(this.vd,c)}break;default:P("Unsupporting sharing policy")}return c}
function Mb(a,b){if(null===b)return this.Xd&&P("null is not a valid "+this.name),0;b.jd||P('Cannot pass "'+Jb(b)+'" as a '+this.name);b.jd.ld||P("Cannot pass deleted object as a pointer of type "+this.name);b.jd.md.Ld&&P("Cannot convert argument of type "+b.jd.md.name+" to parameter type "+this.name);return Hb(b.jd.ld,b.jd.md.kd,this.kd)}function Nb(a,b,c){if(b===c)return a;if(void 0===c.ud)return null;a=Nb(a,b,c.ud);return null===a?null:c.se(a)}var Ob={};
function Pb(a,b){for(void 0===b&&P("ptr should not be undefined");a.ud;)b=a.Hd(b),a=a.ud;return Ob[b]}function Qb(a,b){b.md&&b.ld||ob("makeClassHandle requires ptr and ptrType");!!b.sd!==!!b.qd&&ob("Both smartPtrType and smartPtr must be specified");b.count={value:1};return yb(Object.create(a,{jd:{value:b}}))}
function Rb(a,b,c,d){this.name=a;this.kd=b;this.Xd=c;this.Ld=d;this.Md=!1;this.vd=this.Fe=this.Yd=this.he=this.Je=this.De=void 0;void 0!==b.ud?this.toWireType=Kb:(this.toWireType=d?Ib:Mb,this.rd=null)}function Sb(a,b,c){k.hasOwnProperty(a)||ob("Replacing nonexistant public symbol");void 0!==k[a].nd&&void 0!==c?k[a].nd[c]=b:(k[a]=b,k[a].Kd=c)}
function Tb(a,b){var c=[];return function(){c.length=arguments.length;for(var d=0;d<arguments.length;d++)c[d]=arguments[d];a.includes("j")?(d=k["dynCall_"+a],d=c&&c.length?d.apply(null,[b].concat(c)):d.call(null,b)):d=K(b).apply(null,c);return d}}function Q(a,b){a=O(a);var c=a.includes("j")?Tb(a,b):K(b);"function"!==typeof c&&P("unknown function pointer with signature "+a+": "+b);return c}var Ub=void 0;function Vb(a){a=Wb(a);var b=O(a);Xb(a);return b}
function Yb(a,b){function c(f){e[f]||ib[f]||(jb[f]?jb[f].forEach(c):(d.push(f),e[f]=!0))}var d=[],e={};b.forEach(c);throw new Ub(a+": "+d.map(Vb).join([", "]));}function Zb(a,b,c){a instanceof Object||P(c+' with invalid "this": '+a);a instanceof b.kd.constructor||P(c+' incompatible with "this" of type '+a.constructor.name);a.jd.ld||P("cannot call emscripten binding method "+c+" on deleted object");return Hb(a.jd.ld,a.jd.md.kd,b.kd)}
function $b(a,b){for(var c=[],d=0;d<a;d++)c.push(C[(b>>2)+d]);return c}function ac(a){var b=Function;if(!(b instanceof Function))throw new TypeError("new_ called with constructor type "+typeof b+" which is not a function");var c=lb(b.name||"unknownFunctionName",function(){});c.prototype=b.prototype;c=new c;a=b.apply(c,a);return a instanceof Object?a:c}
function bc(a,b,c,d,e){var f=b.length;2>f&&P("argTypes array size mismatch! Must at least get return value and 'this' types!");var g=null!==b[1]&&null!==c,h=!1;for(c=1;c<b.length;++c)if(null!==b[c]&&void 0===b[c].rd){h=!0;break}var n="void"!==b[0].name,p="",r="";for(c=0;c<f-2;++c)p+=(0!==c?", ":"")+"arg"+c,r+=(0!==c?", ":"")+"arg"+c+"Wired";a="return function "+kb(a)+"("+p+") {\nif (arguments.length !== "+(f-2)+") {\nthrowBindingError('function "+a+" called with ' + arguments.length + ' arguments, expected "+
(f-2)+" args!');\n}\n";h&&(a+="var destructors = [];\n");var x=h?"destructors":"null";p="throwBindingError invoker fn runDestructors retType classParam".split(" ");d=[P,d,e,fb,b[0],b[1]];g&&(a+="var thisWired = classParam.toWireType("+x+", this);\n");for(c=0;c<f-2;++c)a+="var arg"+c+"Wired = argType"+c+".toWireType("+x+", arg"+c+"); // "+b[c+2].name+"\n",p.push("argType"+c),d.push(b[c+2]);g&&(r="thisWired"+(0<r.length?", ":"")+r);a+=(n?"var rv = ":"")+"invoker(fn"+(0<r.length?", ":"")+r+");\n";if(h)a+=
"runDestructors(destructors);\n";else for(c=g?1:2;c<b.length;++c)f=1===c?"thisWired":"arg"+(c-2)+"Wired",null!==b[c].rd&&(a+=f+"_dtor("+f+"); // "+b[c].name+"\n",p.push(f+"_dtor"),d.push(b[c].rd));n&&(a+="var ret = retType.fromWireType(rv);\nreturn ret;\n");p.push(a+"}\n");return ac(p).apply(null,d)}var cc=[],R=[{},{value:void 0},{value:null},{value:!0},{value:!1}];function dc(a){4<a&&0===--R[a].Zd&&(R[a]=void 0,cc.push(a))}
function ec(a){a||P("Cannot use deleted val. handle = "+a);return R[a].value}function Lb(a){switch(a){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:var b=cc.length?cc.pop():R.length;R[b]={Zd:1,value:a};return b}}
function fc(a,b,c){switch(b){case 0:return function(d){return this.fromWireType((c?Pa:z)[d])};case 1:return function(d){return this.fromWireType((c?Ia:Ha)[d>>1])};case 2:return function(d){return this.fromWireType((c?C:D)[d>>2])};default:throw new TypeError("Unknown integer type: "+a);}}function gc(a,b){var c=ib[a];void 0===c&&P(b+" has unknown type "+Vb(a));return c}function Jb(a){if(null===a)return"null";var b=typeof a;return"object"===b||"array"===b||"function"===b?a.toString():""+a}
function hc(a,b){switch(b){case 2:return function(c){return this.fromWireType(G[c>>2])};case 3:return function(c){return this.fromWireType(Qa[c>>3])};default:throw new TypeError("Unknown float type: "+a);}}
function ic(a,b,c){switch(b){case 0:return c?function(d){return Pa[d]}:function(d){return z[d]};case 1:return c?function(d){return Ia[d>>1]}:function(d){return Ha[d>>1]};case 2:return c?function(d){return C[d>>2]}:function(d){return D[d>>2]};default:throw new TypeError("Unknown integer type: "+a);}}var jc={};function kc(a){var b=jc[a];return void 0===b?O(a):b}var lc=[];function mc(a){var b=lc.length;lc.push(a);return b}
function nc(a,b){for(var c=Array(a),d=0;d<a;++d)c[d]=gc(C[(b>>2)+d],"parameter "+d);return c}var oc=[],pc;pc=na?function(){var a=process.hrtime();return 1E3*a[0]+a[1]/1E6}:function(){return performance.now()};
function qc(a){var b=a.getExtension("ANGLE_instanced_arrays");b&&(a.vertexAttribDivisor=function(c,d){b.vertexAttribDivisorANGLE(c,d)},a.drawArraysInstanced=function(c,d,e,f){b.drawArraysInstancedANGLE(c,d,e,f)},a.drawElementsInstanced=function(c,d,e,f,g){b.drawElementsInstancedANGLE(c,d,e,f,g)})}
function rc(a){var b=a.getExtension("OES_vertex_array_object");b&&(a.createVertexArray=function(){return b.createVertexArrayOES()},a.deleteVertexArray=function(c){b.deleteVertexArrayOES(c)},a.bindVertexArray=function(c){b.bindVertexArrayOES(c)},a.isVertexArray=function(c){return b.isVertexArrayOES(c)})}function sc(a){var b=a.getExtension("WEBGL_draw_buffers");b&&(a.drawBuffers=function(c,d){b.drawBuffersWEBGL(c,d)})}
var tc=1,uc=[],S=[],vc=[],wc=[],xc=[],T=[],yc=[],fa=[],zc=[],Ac=[],Bc={},Cc={},Dc=4;function U(a){Ec||(Ec=a)}function ea(a){for(var b=tc++,c=a.length;c<b;c++)a[c]=null;return b}
function ha(a){a||(a=l);if(!a.Be){a.Be=!0;var b=a.Td;qc(b);rc(b);sc(b);b.de=b.getExtension("WEBGL_draw_instanced_base_vertex_base_instance");b.ge=b.getExtension("WEBGL_multi_draw_instanced_base_vertex_base_instance");2<=a.version&&(b.ee=b.getExtension("EXT_disjoint_timer_query_webgl2"));if(2>a.version||!b.ee)b.ee=b.getExtension("EXT_disjoint_timer_query");b.Se=b.getExtension("WEBGL_multi_draw");(b.getSupportedExtensions()||[]).forEach(function(c){c.includes("lose_context")||c.includes("debug")||b.getExtension(c)})}}
var Ec,l,Fc=[];function Gc(a,b,c,d){for(var e=0;e<a;e++){var f=q[c](),g=f&&ea(d);f?(f.name=g,d[g]=f):U(1282);C[b+4*e>>2]=g}}
function Hc(a,b){if(b){var c=void 0;switch(a){case 36346:c=1;break;case 36344:return;case 34814:case 36345:c=0;break;case 34466:var d=q.getParameter(34467);c=d?d.length:0;break;case 33309:if(2>l.version){U(1282);return}c=2*(q.getSupportedExtensions()||[]).length;break;case 33307:case 33308:if(2>l.version){U(1280);return}c=33307==a?3:0}if(void 0===c)switch(d=q.getParameter(a),typeof d){case "number":c=d;break;case "boolean":c=d?1:0;break;case "string":U(1280);return;case "object":if(null===d)switch(a){case 34964:case 35725:case 34965:case 36006:case 36007:case 32873:case 34229:case 36662:case 36663:case 35053:case 35055:case 36010:case 35097:case 35869:case 32874:case 36389:case 35983:case 35368:case 34068:c=
0;break;default:U(1280);return}else{if(d instanceof Float32Array||d instanceof Uint32Array||d instanceof Int32Array||d instanceof Array){for(a=0;a<d.length;++a)C[b+4*a>>2]=d[a];return}try{c=d.name|0}catch(e){U(1280);va("GL_INVALID_ENUM in glGet0v: Unknown object returned from WebGL getParameter("+a+")! (error: "+e+")");return}}break;default:U(1280);va("GL_INVALID_ENUM in glGet0v: Native code calling glGet0v("+a+") and it returns "+d+" of type "+typeof d+"!");return}C[b>>2]=c}else U(1281)}
function Ic(a){var b=Ea(a)+1,c=Jc(b);Da(a,z,c,b);return c}function Kc(a){return"]"==a.slice(-1)&&a.lastIndexOf("[")}function Lc(a){a-=5120;return 0==a?Pa:1==a?z:2==a?Ia:4==a?C:6==a?G:5==a||28922==a||28520==a||30779==a||30782==a?D:Ha}function Mc(a,b,c,d,e){a=Lc(a);var f=31-Math.clz32(a.BYTES_PER_ELEMENT),g=Dc;return a.subarray(e>>f,e+d*(c*({5:3,6:4,8:2,29502:3,29504:4,26917:2,26918:2,29846:3,29847:4}[b-6402]||1)*(1<<f)+g-1&-g)>>f)}
function V(a){var b=q.re;if(b){var c=b.Gd[a];"number"===typeof c&&(b.Gd[a]=c=q.getUniformLocation(b,b.ie[a]+(0<c?"["+c+"]":"")));return c}U(1282)}var Nc=[],Oc=[],Pc={};
function Qc(){if(!Rc){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"===typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ka||"./this.program"},b;for(b in Pc)void 0===Pc[b]?delete a[b]:a[b]=Pc[b];var c=[];for(b in a)c.push(b+"="+a[b]);Rc=c}return Rc}var Rc,Sc=[null,[],[]];function Tc(a){return 0===a%4&&(0!==a%100||0===a%400)}function Uc(a,b){for(var c=0,d=0;d<=b;c+=a[d++]);return c}
var Vc=[31,29,31,30,31,30,31,31,30,31,30,31],Wc=[31,28,31,30,31,30,31,31,30,31,30,31];function Xc(a,b){for(a=new Date(a.getTime());0<b;){var c=a.getMonth(),d=(Tc(a.getFullYear())?Vc:Wc)[c];if(b>d-a.getDate())b-=d-a.getDate()+1,a.setDate(1),11>c?a.setMonth(c+1):(a.setMonth(0),a.setFullYear(a.getFullYear()+1));else{a.setDate(a.getDate()+b);break}}return a}
function Yc(a,b,c,d){function e(m,u,B){for(m="number"===typeof m?m.toString():m||"";m.length<u;)m=B[0]+m;return m}function f(m,u){return e(m,u,"0")}function g(m,u){function B(v){return 0>v?-1:0<v?1:0}var t;0===(t=B(m.getFullYear()-u.getFullYear()))&&0===(t=B(m.getMonth()-u.getMonth()))&&(t=B(m.getDate()-u.getDate()));return t}function h(m){switch(m.getDay()){case 0:return new Date(m.getFullYear()-1,11,29);case 1:return m;case 2:return new Date(m.getFullYear(),0,3);case 3:return new Date(m.getFullYear(),
0,2);case 4:return new Date(m.getFullYear(),0,1);case 5:return new Date(m.getFullYear()-1,11,31);case 6:return new Date(m.getFullYear()-1,11,30)}}function n(m){m=Xc(new Date(m.pd+1900,0,1),m.Sd);var u=new Date(m.getFullYear()+1,0,4),B=h(new Date(m.getFullYear(),0,4));u=h(u);return 0>=g(B,m)?0>=g(u,m)?m.getFullYear()+1:m.getFullYear():m.getFullYear()-1}var p=C[d+40>>2];d={Me:C[d>>2],Le:C[d+4>>2],Qd:C[d+8>>2],Fd:C[d+12>>2],Cd:C[d+16>>2],pd:C[d+20>>2],Rd:C[d+24>>2],Sd:C[d+28>>2],Ue:C[d+32>>2],Ke:C[d+
36>>2],Ne:p?Ca(p):""};c=Ca(c);p={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var r in p)c=c.replace(new RegExp(r,"g"),p[r]);var x="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
A="January February March April May June July August September October November December".split(" ");p={"%a":function(m){return x[m.Rd].substring(0,3)},"%A":function(m){return x[m.Rd]},"%b":function(m){return A[m.Cd].substring(0,3)},"%B":function(m){return A[m.Cd]},"%C":function(m){return f((m.pd+1900)/100|0,2)},"%d":function(m){return f(m.Fd,2)},"%e":function(m){return e(m.Fd,2," ")},"%g":function(m){return n(m).toString().substring(2)},"%G":function(m){return n(m)},"%H":function(m){return f(m.Qd,
2)},"%I":function(m){m=m.Qd;0==m?m=12:12<m&&(m-=12);return f(m,2)},"%j":function(m){return f(m.Fd+Uc(Tc(m.pd+1900)?Vc:Wc,m.Cd-1),3)},"%m":function(m){return f(m.Cd+1,2)},"%M":function(m){return f(m.Le,2)},"%n":function(){return"\n"},"%p":function(m){return 0<=m.Qd&&12>m.Qd?"AM":"PM"},"%S":function(m){return f(m.Me,2)},"%t":function(){return"\t"},"%u":function(m){return m.Rd||7},"%U":function(m){var u=new Date(m.pd+1900,0,1),B=0===u.getDay()?u:Xc(u,7-u.getDay());m=new Date(m.pd+1900,m.Cd,m.Fd);return 0>
g(B,m)?f(Math.ceil((31-B.getDate()+(Uc(Tc(m.getFullYear())?Vc:Wc,m.getMonth()-1)-31)+m.getDate())/7),2):0===g(B,u)?"01":"00"},"%V":function(m){var u=new Date(m.pd+1901,0,4),B=h(new Date(m.pd+1900,0,4));u=h(u);var t=Xc(new Date(m.pd+1900,0,1),m.Sd);return 0>g(t,B)?"53":0>=g(u,t)?"01":f(Math.ceil((B.getFullYear()<m.pd+1900?m.Sd+32-B.getDate():m.Sd+1-B.getDate())/7),2)},"%w":function(m){return m.Rd},"%W":function(m){var u=new Date(m.pd,0,1),B=1===u.getDay()?u:Xc(u,0===u.getDay()?1:7-u.getDay()+1);m=
new Date(m.pd+1900,m.Cd,m.Fd);return 0>g(B,m)?f(Math.ceil((31-B.getDate()+(Uc(Tc(m.getFullYear())?Vc:Wc,m.getMonth()-1)-31)+m.getDate())/7),2):0===g(B,u)?"01":"00"},"%y":function(m){return(m.pd+1900).toString().substring(2)},"%Y":function(m){return m.pd+1900},"%z":function(m){m=m.Ke;var u=0<=m;m=Math.abs(m)/60;return(u?"+":"-")+String("0000"+(m/60*100+m%60)).slice(-4)},"%Z":function(m){return m.Ne},"%%":function(){return"%"}};for(r in p)c.includes(r)&&(c=c.replace(new RegExp(r,"g"),p[r](d)));r=Zc(c);
if(r.length>b)return 0;Pa.set(r,a);return r.length-1}nb=k.InternalError=mb("InternalError");for(var $c=Array(256),ad=0;256>ad;++ad)$c[ad]=String.fromCharCode(ad);sb=$c;tb=k.BindingError=mb("BindingError");Cb.prototype.isAliasOf=function(a){if(!(this instanceof Cb&&a instanceof Cb))return!1;var b=this.jd.md.kd,c=this.jd.ld,d=a.jd.md.kd;for(a=a.jd.ld;b.ud;)c=b.Hd(c),b=b.ud;for(;d.ud;)a=d.Hd(a),d=d.ud;return b===d&&c===a};
Cb.prototype.clone=function(){this.jd.ld||ub(this);if(this.jd.Ed)return this.jd.count.value+=1,this;var a=yb,b=Object,c=b.create,d=Object.getPrototypeOf(this),e=this.jd;a=a(c.call(b,d,{jd:{value:{count:e.count,Bd:e.Bd,Ed:e.Ed,ld:e.ld,md:e.md,qd:e.qd,sd:e.sd}}}));a.jd.count.value+=1;a.jd.Bd=!1;return a};Cb.prototype["delete"]=function(){this.jd.ld||ub(this);this.jd.Bd&&!this.jd.Ed&&P("Object already scheduled for deletion");wb(this);xb(this.jd);this.jd.Ed||(this.jd.qd=void 0,this.jd.ld=void 0)};
Cb.prototype.isDeleted=function(){return!this.jd.ld};Cb.prototype.deleteLater=function(){this.jd.ld||ub(this);this.jd.Bd&&!this.jd.Ed&&P("Object already scheduled for deletion");Ab.push(this);1===Ab.length&&zb&&zb(Bb);this.jd.Bd=!0;return this};Rb.prototype.we=function(a){this.he&&(a=this.he(a));return a};Rb.prototype.ce=function(a){this.vd&&this.vd(a)};Rb.prototype.argPackAdvance=8;Rb.prototype.readValueFromPointer=gb;Rb.prototype.deleteObject=function(a){if(null!==a)a["delete"]()};
Rb.prototype.fromWireType=function(a){function b(){return this.Md?Qb(this.kd.wd,{md:this.De,ld:c,sd:this,qd:a}):Qb(this.kd.wd,{md:this,ld:a})}var c=this.we(a);if(!c)return this.ce(a),null;var d=Pb(this.kd,c);if(void 0!==d){if(0===d.jd.count.value)return d.jd.ld=c,d.jd.qd=a,d.clone();d=d.clone();this.ce(a);return d}d=this.kd.ve(c);d=Db[d];if(!d)return b.call(this);d=this.Ld?d.pe:d.pointerType;var e=Nb(c,this.kd,d.kd);return null===e?b.call(this):this.Md?Qb(d.kd.wd,{md:d,ld:e,sd:this,qd:a}):Qb(d.kd.wd,
{md:d,ld:e})};k.getInheritedInstanceCount=function(){return Object.keys(Ob).length};k.getLiveInheritedInstances=function(){var a=[],b;for(b in Ob)Ob.hasOwnProperty(b)&&a.push(Ob[b]);return a};k.flushPendingDeletes=Bb;k.setDelayFunction=function(a){zb=a;Ab.length&&zb&&zb(Bb)};Ub=k.UnboundTypeError=mb("UnboundTypeError");k.count_emval_handles=function(){for(var a=0,b=5;b<R.length;++b)void 0!==R[b]&&++a;return a};k.get_first_emval=function(){for(var a=5;a<R.length;++a)if(void 0!==R[a])return R[a];return null};
for(var q,W=0;32>W;++W)Fc.push(Array(W));var bd=new Float32Array(288);for(W=0;288>W;++W)Nc[W]=bd.subarray(0,W+1);var cd=new Int32Array(288);for(W=0;288>W;++W)Oc[W]=cd.subarray(0,W+1);function Zc(a){var b=Array(Ea(a)+1);Da(a,b,0,b.length);return b}
var td={bb:function(a){var b=eb[a];delete eb[a];var c=b.Yd,d=b.vd,e=b.fe,f=e.map(function(g){return g.ze}).concat(e.map(function(g){return g.He}));M([a],f,function(g){var h={};e.forEach(function(n,p){var r=g[p],x=n.xe,A=n.ye,m=g[p+e.length],u=n.Ge,B=n.Ie;h[n.ue]={read:function(t){return r.fromWireType(x(A,t))},write:function(t,v){var y=[];u(B,t,m.toWireType(y,v));fb(y)}}});return[{name:b.name,fromWireType:function(n){var p={},r;for(r in h)p[r]=h[r].read(n);d(n);return p},toWireType:function(n,p){for(var r in h)if(!(r in
p))throw new TypeError('Missing field:  "'+r+'"');var x=c();for(r in h)h[r].write(x,p[r]);null!==n&&n.push(d,x);return x},argPackAdvance:8,readValueFromPointer:gb,rd:d}]})},fb:function(){},vb:function(a,b,c,d,e){var f=rb(c);b=O(b);pb(a,{name:b,fromWireType:function(g){return!!g},toWireType:function(g,h){return h?d:e},argPackAdvance:8,readValueFromPointer:function(g){if(1===c)var h=Pa;else if(2===c)h=Ia;else if(4===c)h=C;else throw new TypeError("Unknown boolean type size: "+b);return this.fromWireType(h[g>>
f])},rd:null})},g:function(a,b,c,d,e,f,g,h,n,p,r,x,A){r=O(r);f=Q(e,f);h&&(h=Q(g,h));p&&(p=Q(n,p));A=Q(x,A);var m=kb(r);Fb(m,function(){Yb("Cannot construct "+r+" due to unbound types",[d])});M([a,b,c],d?[d]:[],function(u){u=u[0];if(d){var B=u.kd;var t=B.wd}else t=Cb.prototype;u=lb(m,function(){if(Object.getPrototypeOf(this)!==v)throw new tb("Use 'new' to construct "+r);if(void 0===y.xd)throw new tb(r+" has no accessible constructor");var L=y.xd[arguments.length];if(void 0===L)throw new tb("Tried to invoke ctor of "+
r+" with invalid number of parameters ("+arguments.length+") - expected ("+Object.keys(y.xd).toString()+") parameters instead!");return L.apply(this,arguments)});var v=Object.create(t,{constructor:{value:u}});u.prototype=v;var y=new Gb(r,u,v,A,B,f,h,p);B=new Rb(r,y,!0,!1);t=new Rb(r+"*",y,!1,!1);var E=new Rb(r+" const*",y,!1,!0);Db[a]={pointerType:t,pe:E};Sb(m,u);return[B,t,E]})},k:function(a,b,c,d,e,f,g,h){b=O(b);f=Q(e,f);M([],[a],function(n){n=n[0];var p=n.name+"."+b,r={get:function(){Yb("Cannot access "+
p+" due to unbound types",[c])},enumerable:!0,configurable:!0};r.set=h?function(){Yb("Cannot access "+p+" due to unbound types",[c])}:function(){P(p+" is a read-only property")};Object.defineProperty(n.kd.constructor,b,r);M([],[c],function(x){x=x[0];var A={get:function(){return x.fromWireType(f(d))},enumerable:!0};h&&(h=Q(g,h),A.set=function(m){var u=[];h(d,x.toWireType(u,m));fb(u)});Object.defineProperty(n.kd.constructor,b,A);return[]});return[]})},s:function(a,b,c,d,e,f){0<b||ta("Assertion failed: undefined");
var g=$b(b,c);e=Q(d,e);M([],[a],function(h){h=h[0];var n="constructor "+h.name;void 0===h.kd.xd&&(h.kd.xd=[]);if(void 0!==h.kd.xd[b-1])throw new tb("Cannot register multiple constructors with identical number of parameters ("+(b-1)+") for class '"+h.name+"'! Overload resolution is currently only performed using the parameter count, not actual type info!");h.kd.xd[b-1]=function(){Yb("Cannot construct "+h.name+" due to unbound types",g)};M([],g,function(p){p.splice(1,0,null);h.kd.xd[b-1]=bc(n,p,null,
e,f);return[]});return[]})},e:function(a,b,c,d,e,f,g,h){var n=$b(c,d);b=O(b);f=Q(e,f);M([],[a],function(p){function r(){Yb("Cannot call "+x+" due to unbound types",n)}p=p[0];var x=p.name+"."+b;b.startsWith("@@")&&(b=Symbol[b.substring(2)]);h&&p.kd.Ee.push(b);var A=p.kd.wd,m=A[b];void 0===m||void 0===m.nd&&m.className!==p.name&&m.Kd===c-2?(r.Kd=c-2,r.className=p.name,A[b]=r):(Eb(A,b,x),A[b].nd[c-2]=r);M([],n,function(u){u=bc(x,u,p,f,g);void 0===A[b].nd?(u.Kd=c-2,A[b]=u):A[b].nd[c-2]=u;return[]});return[]})},
f:function(a,b,c,d,e,f,g,h,n,p){b=O(b);e=Q(d,e);M([],[a],function(r){r=r[0];var x=r.name+"."+b,A={get:function(){Yb("Cannot access "+x+" due to unbound types",[c,g])},enumerable:!0,configurable:!0};A.set=n?function(){Yb("Cannot access "+x+" due to unbound types",[c,g])}:function(){P(x+" is a read-only property")};Object.defineProperty(r.kd.wd,b,A);M([],n?[c,g]:[c],function(m){var u=m[0],B={get:function(){var v=Zb(this,r,x+" getter");return u.fromWireType(e(f,v))},enumerable:!0};if(n){n=Q(h,n);var t=
m[1];B.set=function(v){var y=Zb(this,r,x+" setter"),E=[];n(p,y,t.toWireType(E,v));fb(E)}}Object.defineProperty(r.kd.wd,b,B);return[]});return[]})},ub:function(a,b){b=O(b);pb(a,{name:b,fromWireType:function(c){var d=ec(c);dc(c);return d},toWireType:function(c,d){return Lb(d)},argPackAdvance:8,readValueFromPointer:gb,rd:null})},mb:function(a,b,c,d){function e(){}c=rb(c);b=O(b);e.values={};pb(a,{name:b,constructor:e,fromWireType:function(f){return this.constructor.values[f]},toWireType:function(f,g){return g.value},
argPackAdvance:8,readValueFromPointer:fc(b,c,d),rd:null});Fb(b,e)},jb:function(a,b,c){var d=gc(a,"enum");b=O(b);a=d.constructor;d=Object.create(d.constructor.prototype,{value:{value:c},constructor:{value:lb(d.name+"_"+b,function(){})}});a.values[c]=d;a[b]=d},x:function(a,b,c){c=rb(c);b=O(b);pb(a,{name:b,fromWireType:function(d){return d},toWireType:function(d,e){return e},argPackAdvance:8,readValueFromPointer:hc(b,c),rd:null})},z:function(a,b,c,d,e,f){var g=$b(b,c);a=O(a);e=Q(d,e);Fb(a,function(){Yb("Cannot call "+
a+" due to unbound types",g)},b-1);M([],g,function(h){h=[h[0],null].concat(h.slice(1));Sb(a,bc(a,h,null,e,f),b-1);return[]})},m:function(a,b,c,d,e){function f(p){return p}b=O(b);-1===e&&(e=4294967295);var g=rb(c);if(0===d){var h=32-8*c;f=function(p){return p<<h>>>h}}var n=b.includes("unsigned");pb(a,{name:b,fromWireType:f,toWireType:function(p,r){if("number"!==typeof r&&"boolean"!==typeof r)throw new TypeError('Cannot convert "'+Jb(r)+'" to '+this.name);if(r<d||r>e)throw new TypeError('Passing a number "'+
Jb(r)+'" from JS side to C/C++ side to an argument of type "'+b+'", which is outside the valid range ['+d+", "+e+"]!");return n?r>>>0:r|0},argPackAdvance:8,readValueFromPointer:ic(b,g,0!==d),rd:null})},l:function(a,b,c){function d(f){f>>=2;var g=D;return new e(Oa,g[f+1],g[f])}var e=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][b];c=O(c);pb(a,{name:c,fromWireType:d,argPackAdvance:8,readValueFromPointer:d},{Ae:!0})},y:function(a,b){b=O(b);var c="std::string"===
b;pb(a,{name:b,fromWireType:function(d){var e=D[d>>2];if(c)for(var f=d+4,g=0;g<=e;++g){var h=d+4+g;if(g==e||0==z[h]){f=Ca(f,h-f);if(void 0===n)var n=f;else n+=String.fromCharCode(0),n+=f;f=h+1}}else{n=Array(e);for(g=0;g<e;++g)n[g]=String.fromCharCode(z[d+4+g]);n=n.join("")}Xb(d);return n},toWireType:function(d,e){e instanceof ArrayBuffer&&(e=new Uint8Array(e));var f="string"===typeof e;f||e instanceof Uint8Array||e instanceof Uint8ClampedArray||e instanceof Int8Array||P("Cannot pass non-string to std::string");
var g=(c&&f?function(){return Ea(e)}:function(){return e.length})(),h=Jc(4+g+1);D[h>>2]=g;if(c&&f)Da(e,z,h+4,g+1);else if(f)for(f=0;f<g;++f){var n=e.charCodeAt(f);255<n&&(Xb(h),P("String has UTF-16 code units that do not fit in 8 bits"));z[h+4+f]=n}else for(f=0;f<g;++f)z[h+4+f]=e[f];null!==d&&d.push(Xb,h);return h},argPackAdvance:8,readValueFromPointer:gb,rd:function(d){Xb(d)}})},v:function(a,b,c){c=O(c);if(2===b){var d=Ga;var e=Ja;var f=Ka;var g=function(){return Ha};var h=1}else 4===b&&(d=La,e=
Ma,f=Na,g=function(){return D},h=2);pb(a,{name:c,fromWireType:function(n){for(var p=D[n>>2],r=g(),x,A=n+4,m=0;m<=p;++m){var u=n+4+m*b;if(m==p||0==r[u>>h])A=d(A,u-A),void 0===x?x=A:(x+=String.fromCharCode(0),x+=A),A=u+b}Xb(n);return x},toWireType:function(n,p){"string"!==typeof p&&P("Cannot pass non-string to C++ string type "+c);var r=f(p),x=Jc(4+r+b);D[x>>2]=r>>h;e(p,x+4,r+b);null!==n&&n.push(Xb,x);return x},argPackAdvance:8,readValueFromPointer:gb,rd:function(n){Xb(n)}})},cb:function(a,b,c,d,e,
f){eb[a]={name:O(b),Yd:Q(c,d),vd:Q(e,f),fe:[]}},ab:function(a,b,c,d,e,f,g,h,n,p){eb[a].fe.push({ue:O(b),ze:c,xe:Q(d,e),ye:f,He:g,Ge:Q(h,n),Ie:p})},wb:function(a,b){b=O(b);pb(a,{Ce:!0,name:b,argPackAdvance:0,fromWireType:function(){},toWireType:function(){}})},nb:function(){throw"longjmp";},pa:function(a,b,c){a=ec(a);b=gc(b,"emval::as");var d=[],e=Lb(d);C[c>>2]=e;return b.toWireType(d,a)},K:function(a,b,c,d){a=lc[a];b=ec(b);c=kc(c);a(b,c,null,d)},Gc:dc,Fc:function(a,b){var c=nc(a,b),d=c[0];b=d.name+
"_$"+c.slice(1).map(function(r){return r.name}).join("_")+"$";var e=oc[b];if(void 0!==e)return e;e=["retType"];for(var f=[d],g="",h=0;h<a-1;++h)g+=(0!==h?", ":"")+"arg"+h,e.push("argType"+h),f.push(c[1+h]);var n="return function "+kb("methodCaller_"+b)+"(handle, name, destructors, args) {\n",p=0;for(h=0;h<a-1;++h)n+="    var arg"+h+" = argType"+h+".readValueFromPointer(args"+(p?"+"+p:"")+");\n",p+=c[h+1].argPackAdvance;n+="    var rv = handle[name]("+g+");\n";for(h=0;h<a-1;++h)c[h+1].deleteObject&&
(n+="    argType"+h+".deleteObject(arg"+h+");\n");d.Ce||(n+="    return retType.toWireType(destructors, rv);\n");e.push(n+"};\n");a=ac(e).apply(null,f);e=mc(a);return oc[b]=e},La:function(a,b){a=ec(a);b=ec(b);return Lb(a[b])},uc:function(a){4<a&&(R[a].Zd+=1)},Aa:function(a){return Lb(kc(a))},ea:function(a){var b=ec(a);fb(b);dc(a)},V:function(a,b){a=gc(a,"_emval_take_value");a=a.readValueFromPointer(b);return Lb(a)},a:function(){ta("")},pb:function(a,b){if(0===a)a=Date.now();else if(1===a||4===a)a=
pc();else return C[dd()>>2]=28,-1;C[b>>2]=a/1E3|0;C[b+4>>2]=a%1E3*1E6|0;return 0},Xa:function(a){q.activeTexture(a)},Wa:function(a,b){q.attachShader(S[a],T[b])},Va:function(a,b,c){q.bindAttribLocation(S[a],b,Ca(c))},Ua:function(a,b){35051==a?q.Wd=b:35052==a&&(q.Ad=b);q.bindBuffer(a,uc[b])},Wb:function(a,b){q.bindFramebuffer(a,vc[b])},Vb:function(a,b){q.bindRenderbuffer(a,wc[b])},Eb:function(a,b){q.bindSampler(a,zc[b])},Ta:function(a,b){q.bindTexture(a,xc[b])},mc:function(a){q.bindVertexArray(yc[a])},
jc:function(a){q.bindVertexArray(yc[a])},Sa:function(a,b,c,d){q.blendColor(a,b,c,d)},Ra:function(a){q.blendEquation(a)},Qa:function(a,b){q.blendFunc(a,b)},Jb:function(a,b,c,d,e,f,g,h,n,p){q.blitFramebuffer(a,b,c,d,e,f,g,h,n,p)},Pa:function(a,b,c,d){2<=l.version?c?q.bufferData(a,z,d,c,b):q.bufferData(a,b,d):q.bufferData(a,c?z.subarray(c,c+b):b,d)},Oa:function(a,b,c,d){2<=l.version?q.bufferSubData(a,b,z,d,c):q.bufferSubData(a,b,z.subarray(d,d+c))},Ub:function(a){return q.checkFramebufferStatus(a)},
Na:function(a){q.clear(a)},Ma:function(a,b,c,d){q.clearColor(a,b,c,d)},Ka:function(a){q.clearStencil(a)},_a:function(a,b,c,d){return q.clientWaitSync(Ac[a],b,(c>>>0)+4294967296*d)},Ja:function(a,b,c,d){q.colorMask(!!a,!!b,!!c,!!d)},Ia:function(a){q.compileShader(T[a])},Ha:function(a,b,c,d,e,f,g,h){2<=l.version?q.Ad?q.compressedTexImage2D(a,b,c,d,e,f,g,h):q.compressedTexImage2D(a,b,c,d,e,f,z,h,g):q.compressedTexImage2D(a,b,c,d,e,f,h?z.subarray(h,h+g):null)},Ga:function(a,b,c,d,e,f,g,h,n){2<=l.version?
q.Ad?q.compressedTexSubImage2D(a,b,c,d,e,f,g,h,n):q.compressedTexSubImage2D(a,b,c,d,e,f,g,z,n,h):q.compressedTexSubImage2D(a,b,c,d,e,f,g,n?z.subarray(n,n+h):null)},Fa:function(a,b,c,d,e,f,g,h){q.copyTexSubImage2D(a,b,c,d,e,f,g,h)},Ea:function(){var a=ea(S),b=q.createProgram();b.name=a;b.Pd=b.Nd=b.Od=0;b.$d=1;S[a]=b;return a},Da:function(a){var b=ea(T);T[b]=q.createShader(a);return b},Ca:function(a){q.cullFace(a)},Ba:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2],e=uc[d];e&&(q.deleteBuffer(e),
e.name=0,uc[d]=null,d==q.Wd&&(q.Wd=0),d==q.Ad&&(q.Ad=0))}},Tb:function(a,b){for(var c=0;c<a;++c){var d=C[b+4*c>>2],e=vc[d];e&&(q.deleteFramebuffer(e),e.name=0,vc[d]=null)}},za:function(a){if(a){var b=S[a];b?(q.deleteProgram(b),b.name=0,S[a]=null):U(1281)}},Sb:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2],e=wc[d];e&&(q.deleteRenderbuffer(e),e.name=0,wc[d]=null)}},Db:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2],e=zc[d];e&&(q.deleteSampler(e),e.name=0,zc[d]=null)}},ya:function(a){if(a){var b=
T[a];b?(q.deleteShader(b),T[a]=null):U(1281)}},Hb:function(a){if(a){var b=Ac[a];b?(q.deleteSync(b),b.name=0,Ac[a]=null):U(1281)}},xa:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2],e=xc[d];e&&(q.deleteTexture(e),e.name=0,xc[d]=null)}},lc:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2];q.deleteVertexArray(yc[d]);yc[d]=null}},ic:function(a,b){for(var c=0;c<a;c++){var d=C[b+4*c>>2];q.deleteVertexArray(yc[d]);yc[d]=null}},wa:function(a){q.depthMask(!!a)},va:function(a){q.disable(a)},ua:function(a){q.disableVertexAttribArray(a)},
ta:function(a,b,c){q.drawArrays(a,b,c)},gc:function(a,b,c,d){q.drawArraysInstanced(a,b,c,d)},ec:function(a,b,c,d,e){q.de.drawArraysInstancedBaseInstanceWEBGL(a,b,c,d,e)},cc:function(a,b){for(var c=Fc[a],d=0;d<a;d++)c[d]=C[b+4*d>>2];q.drawBuffers(c)},sa:function(a,b,c,d){q.drawElements(a,b,c,d)},fc:function(a,b,c,d,e){q.drawElementsInstanced(a,b,c,d,e)},dc:function(a,b,c,d,e,f,g){q.de.drawElementsInstancedBaseVertexBaseInstanceWEBGL(a,b,c,d,e,f,g)},_b:function(a,b,c,d,e,f){q.drawElements(a,d,e,f)},
ra:function(a){q.enable(a)},qa:function(a){q.enableVertexAttribArray(a)},Gb:function(a,b){return(a=q.fenceSync(a,b))?(b=ea(Ac),a.name=b,Ac[b]=a,b):0},oa:function(){q.finish()},na:function(){q.flush()},Rb:function(a,b,c,d){q.framebufferRenderbuffer(a,b,c,wc[d])},Qb:function(a,b,c,d,e){q.framebufferTexture2D(a,b,c,xc[d],e)},ma:function(a){q.frontFace(a)},la:function(a,b){Gc(a,b,"createBuffer",uc)},Pb:function(a,b){Gc(a,b,"createFramebuffer",vc)},Ob:function(a,b){Gc(a,b,"createRenderbuffer",wc)},Cb:function(a,
b){Gc(a,b,"createSampler",zc)},ka:function(a,b){Gc(a,b,"createTexture",xc)},kc:function(a,b){Gc(a,b,"createVertexArray",yc)},hc:function(a,b){Gc(a,b,"createVertexArray",yc)},Nb:function(a){q.generateMipmap(a)},ja:function(a,b,c){c?C[c>>2]=q.getBufferParameter(a,b):U(1281)},ia:function(){var a=q.getError()||Ec;Ec=0;return a},Mb:function(a,b,c,d){a=q.getFramebufferAttachmentParameter(a,b,c);if(a instanceof WebGLRenderbuffer||a instanceof WebGLTexture)a=a.name|0;C[d>>2]=a},Ya:function(a,b){Hc(a,b)},
ha:function(a,b,c,d){a=q.getProgramInfoLog(S[a]);null===a&&(a="(unknown error)");b=0<b&&d?Da(a,z,d,b):0;c&&(C[c>>2]=b)},ga:function(a,b,c){if(c)if(a>=tc)U(1281);else if(a=S[a],35716==b)a=q.getProgramInfoLog(a),null===a&&(a="(unknown error)"),C[c>>2]=a.length+1;else if(35719==b){if(!a.Pd)for(b=0;b<q.getProgramParameter(a,35718);++b)a.Pd=Math.max(a.Pd,q.getActiveUniform(a,b).name.length+1);C[c>>2]=a.Pd}else if(35722==b){if(!a.Nd)for(b=0;b<q.getProgramParameter(a,35721);++b)a.Nd=Math.max(a.Nd,q.getActiveAttrib(a,
b).name.length+1);C[c>>2]=a.Nd}else if(35381==b){if(!a.Od)for(b=0;b<q.getProgramParameter(a,35382);++b)a.Od=Math.max(a.Od,q.getActiveUniformBlockName(a,b).length+1);C[c>>2]=a.Od}else C[c>>2]=q.getProgramParameter(a,b);else U(1281)},Lb:function(a,b,c){c?C[c>>2]=q.getRenderbufferParameter(a,b):U(1281)},fa:function(a,b,c,d){a=q.getShaderInfoLog(T[a]);null===a&&(a="(unknown error)");b=0<b&&d?Da(a,z,d,b):0;c&&(C[c>>2]=b)},xb:function(a,b,c,d){a=q.getShaderPrecisionFormat(a,b);C[c>>2]=a.rangeMin;C[c+4>>
2]=a.rangeMax;C[d>>2]=a.precision},da:function(a,b,c){c?35716==b?(a=q.getShaderInfoLog(T[a]),null===a&&(a="(unknown error)"),C[c>>2]=a?a.length+1:0):35720==b?(a=q.getShaderSource(T[a]),C[c>>2]=a?a.length+1:0):C[c>>2]=q.getShaderParameter(T[a],b):U(1281)},t:function(a){var b=Bc[a];if(!b){switch(a){case 7939:b=q.getSupportedExtensions()||[];b=b.concat(b.map(function(d){return"GL_"+d}));b=Ic(b.join(" "));break;case 7936:case 7937:case 37445:case 37446:(b=q.getParameter(a))||U(1280);b=b&&Ic(b);break;
case 7938:b=q.getParameter(7938);b=2<=l.version?"OpenGL ES 3.0 ("+b+")":"OpenGL ES 2.0 ("+b+")";b=Ic(b);break;case 35724:b=q.getParameter(35724);var c=b.match(/^WebGL GLSL ES ([0-9]\.[0-9][0-9]?)(?:$| .*)/);null!==c&&(3==c[1].length&&(c[1]+="0"),b="OpenGL ES GLSL ES "+c[1]+" ("+b+")");b=Ic(b);break;default:U(1280)}Bc[a]=b}return b},Za:function(a,b){if(2>l.version)return U(1282),0;var c=Cc[a];if(c)return 0>b||b>=c.length?(U(1281),0):c[b];switch(a){case 7939:return c=q.getSupportedExtensions()||[],
c=c.concat(c.map(function(d){return"GL_"+d})),c=c.map(function(d){return Ic(d)}),c=Cc[a]=c,0>b||b>=c.length?(U(1281),0):c[b];default:return U(1280),0}},ca:function(a,b){b=Ca(b);if(a=S[a]){var c=a,d=c.Gd,e=c.je,f;if(!d)for(c.Gd=d={},c.ie={},f=0;f<q.getProgramParameter(c,35718);++f){var g=q.getActiveUniform(c,f);var h=g.name;g=g.size;var n=Kc(h);n=0<n?h.slice(0,n):h;var p=c.$d;c.$d+=g;e[n]=[g,p];for(h=0;h<g;++h)d[p]=h,c.ie[p++]=n}c=a.Gd;d=0;e=b;f=Kc(b);0<f&&(d=parseInt(b.slice(f+1))>>>0,e=b.slice(0,
f));if((e=a.je[e])&&d<e[0]&&(d+=e[1],c[d]=c[d]||q.getUniformLocation(a,b)))return d}else U(1281);return-1},zb:function(a,b,c){for(var d=Fc[b],e=0;e<b;e++)d[e]=C[c+4*e>>2];q.invalidateFramebuffer(a,d)},yb:function(a,b,c,d,e,f,g){for(var h=Fc[b],n=0;n<b;n++)h[n]=C[c+4*n>>2];q.invalidateSubFramebuffer(a,h,d,e,f,g)},Fb:function(a){return q.isSync(Ac[a])},ba:function(a){return(a=xc[a])?q.isTexture(a):0},aa:function(a){q.lineWidth(a)},$:function(a){a=S[a];q.linkProgram(a);a.Gd=0;a.je={}},ac:function(a,
b,c,d,e,f){q.ge.multiDrawArraysInstancedBaseInstanceWEBGL(a,C,b>>2,C,c>>2,C,d>>2,D,e>>2,f)},$b:function(a,b,c,d,e,f,g,h){q.ge.multiDrawElementsInstancedBaseVertexBaseInstanceWEBGL(a,C,b>>2,c,C,d>>2,C,e>>2,C,f>>2,D,g>>2,h)},_:function(a,b){3317==a&&(Dc=b);q.pixelStorei(a,b)},bc:function(a){q.readBuffer(a)},Z:function(a,b,c,d,e,f,g){if(2<=l.version)if(q.Wd)q.readPixels(a,b,c,d,e,f,g);else{var h=Lc(f);q.readPixels(a,b,c,d,e,f,h,g>>31-Math.clz32(h.BYTES_PER_ELEMENT))}else(g=Mc(f,e,c,d,g))?q.readPixels(a,
b,c,d,e,f,g):U(1280)},Kb:function(a,b,c,d){q.renderbufferStorage(a,b,c,d)},Ib:function(a,b,c,d,e){q.renderbufferStorageMultisample(a,b,c,d,e)},Bb:function(a,b,c){q.samplerParameteri(zc[a],b,c)},Ab:function(a,b,c){q.samplerParameteri(zc[a],b,C[c>>2])},Y:function(a,b,c,d){q.scissor(a,b,c,d)},X:function(a,b,c,d){for(var e="",f=0;f<b;++f){var g=d?C[d+4*f>>2]:-1;e+=Ca(C[c+4*f>>2],0>g?void 0:g)}q.shaderSource(T[a],e)},W:function(a,b,c){q.stencilFunc(a,b,c)},U:function(a,b,c,d){q.stencilFuncSeparate(a,b,
c,d)},T:function(a){q.stencilMask(a)},S:function(a,b){q.stencilMaskSeparate(a,b)},R:function(a,b,c){q.stencilOp(a,b,c)},Q:function(a,b,c,d){q.stencilOpSeparate(a,b,c,d)},P:function(a,b,c,d,e,f,g,h,n){if(2<=l.version)if(q.Ad)q.texImage2D(a,b,c,d,e,f,g,h,n);else if(n){var p=Lc(h);q.texImage2D(a,b,c,d,e,f,g,h,p,n>>31-Math.clz32(p.BYTES_PER_ELEMENT))}else q.texImage2D(a,b,c,d,e,f,g,h,null);else q.texImage2D(a,b,c,d,e,f,g,h,n?Mc(h,g,d,e,n):null)},O:function(a,b,c){q.texParameterf(a,b,c)},N:function(a,
b,c){q.texParameterf(a,b,G[c>>2])},M:function(a,b,c){q.texParameteri(a,b,c)},L:function(a,b,c){q.texParameteri(a,b,C[c>>2])},Zb:function(a,b,c,d,e){q.texStorage2D(a,b,c,d,e)},J:function(a,b,c,d,e,f,g,h,n){if(2<=l.version)if(q.Ad)q.texSubImage2D(a,b,c,d,e,f,g,h,n);else if(n){var p=Lc(h);q.texSubImage2D(a,b,c,d,e,f,g,h,p,n>>31-Math.clz32(p.BYTES_PER_ELEMENT))}else q.texSubImage2D(a,b,c,d,e,f,g,h,null);else p=null,n&&(p=Mc(h,g,e,f,n)),q.texSubImage2D(a,b,c,d,e,f,g,h,p)},I:function(a,b){q.uniform1f(V(a),
b)},H:function(a,b,c){if(2<=l.version)q.uniform1fv(V(a),G,c>>2,b);else{if(288>=b)for(var d=Nc[b-1],e=0;e<b;++e)d[e]=G[c+4*e>>2];else d=G.subarray(c>>2,c+4*b>>2);q.uniform1fv(V(a),d)}},G:function(a,b){q.uniform1i(V(a),b)},F:function(a,b,c){if(2<=l.version)q.uniform1iv(V(a),C,c>>2,b);else{if(288>=b)for(var d=Oc[b-1],e=0;e<b;++e)d[e]=C[c+4*e>>2];else d=C.subarray(c>>2,c+4*b>>2);q.uniform1iv(V(a),d)}},E:function(a,b,c){q.uniform2f(V(a),b,c)},D:function(a,b,c){if(2<=l.version)q.uniform2fv(V(a),G,c>>2,
2*b);else{if(144>=b)for(var d=Nc[2*b-1],e=0;e<2*b;e+=2)d[e]=G[c+4*e>>2],d[e+1]=G[c+(4*e+4)>>2];else d=G.subarray(c>>2,c+8*b>>2);q.uniform2fv(V(a),d)}},C:function(a,b,c){q.uniform2i(V(a),b,c)},B:function(a,b,c){if(2<=l.version)q.uniform2iv(V(a),C,c>>2,2*b);else{if(144>=b)for(var d=Oc[2*b-1],e=0;e<2*b;e+=2)d[e]=C[c+4*e>>2],d[e+1]=C[c+(4*e+4)>>2];else d=C.subarray(c>>2,c+8*b>>2);q.uniform2iv(V(a),d)}},A:function(a,b,c,d){q.uniform3f(V(a),b,c,d)},Ec:function(a,b,c){if(2<=l.version)q.uniform3fv(V(a),G,
c>>2,3*b);else{if(96>=b)for(var d=Nc[3*b-1],e=0;e<3*b;e+=3)d[e]=G[c+4*e>>2],d[e+1]=G[c+(4*e+4)>>2],d[e+2]=G[c+(4*e+8)>>2];else d=G.subarray(c>>2,c+12*b>>2);q.uniform3fv(V(a),d)}},Dc:function(a,b,c,d){q.uniform3i(V(a),b,c,d)},Cc:function(a,b,c){if(2<=l.version)q.uniform3iv(V(a),C,c>>2,3*b);else{if(96>=b)for(var d=Oc[3*b-1],e=0;e<3*b;e+=3)d[e]=C[c+4*e>>2],d[e+1]=C[c+(4*e+4)>>2],d[e+2]=C[c+(4*e+8)>>2];else d=C.subarray(c>>2,c+12*b>>2);q.uniform3iv(V(a),d)}},Bc:function(a,b,c,d,e){q.uniform4f(V(a),b,
c,d,e)},Ac:function(a,b,c){if(2<=l.version)q.uniform4fv(V(a),G,c>>2,4*b);else{if(72>=b){var d=Nc[4*b-1],e=G;c>>=2;for(var f=0;f<4*b;f+=4){var g=c+f;d[f]=e[g];d[f+1]=e[g+1];d[f+2]=e[g+2];d[f+3]=e[g+3]}}else d=G.subarray(c>>2,c+16*b>>2);q.uniform4fv(V(a),d)}},zc:function(a,b,c,d,e){q.uniform4i(V(a),b,c,d,e)},yc:function(a,b,c){if(2<=l.version)q.uniform4iv(V(a),C,c>>2,4*b);else{if(72>=b)for(var d=Oc[4*b-1],e=0;e<4*b;e+=4)d[e]=C[c+4*e>>2],d[e+1]=C[c+(4*e+4)>>2],d[e+2]=C[c+(4*e+8)>>2],d[e+3]=C[c+(4*e+
12)>>2];else d=C.subarray(c>>2,c+16*b>>2);q.uniform4iv(V(a),d)}},xc:function(a,b,c,d){if(2<=l.version)q.uniformMatrix2fv(V(a),!!c,G,d>>2,4*b);else{if(72>=b)for(var e=Nc[4*b-1],f=0;f<4*b;f+=4)e[f]=G[d+4*f>>2],e[f+1]=G[d+(4*f+4)>>2],e[f+2]=G[d+(4*f+8)>>2],e[f+3]=G[d+(4*f+12)>>2];else e=G.subarray(d>>2,d+16*b>>2);q.uniformMatrix2fv(V(a),!!c,e)}},wc:function(a,b,c,d){if(2<=l.version)q.uniformMatrix3fv(V(a),!!c,G,d>>2,9*b);else{if(32>=b)for(var e=Nc[9*b-1],f=0;f<9*b;f+=9)e[f]=G[d+4*f>>2],e[f+1]=G[d+(4*
f+4)>>2],e[f+2]=G[d+(4*f+8)>>2],e[f+3]=G[d+(4*f+12)>>2],e[f+4]=G[d+(4*f+16)>>2],e[f+5]=G[d+(4*f+20)>>2],e[f+6]=G[d+(4*f+24)>>2],e[f+7]=G[d+(4*f+28)>>2],e[f+8]=G[d+(4*f+32)>>2];else e=G.subarray(d>>2,d+36*b>>2);q.uniformMatrix3fv(V(a),!!c,e)}},vc:function(a,b,c,d){if(2<=l.version)q.uniformMatrix4fv(V(a),!!c,G,d>>2,16*b);else{if(18>=b){var e=Nc[16*b-1],f=G;d>>=2;for(var g=0;g<16*b;g+=16){var h=d+g;e[g]=f[h];e[g+1]=f[h+1];e[g+2]=f[h+2];e[g+3]=f[h+3];e[g+4]=f[h+4];e[g+5]=f[h+5];e[g+6]=f[h+6];e[g+7]=f[h+
7];e[g+8]=f[h+8];e[g+9]=f[h+9];e[g+10]=f[h+10];e[g+11]=f[h+11];e[g+12]=f[h+12];e[g+13]=f[h+13];e[g+14]=f[h+14];e[g+15]=f[h+15]}}else e=G.subarray(d>>2,d+64*b>>2);q.uniformMatrix4fv(V(a),!!c,e)}},tc:function(a){a=S[a];q.useProgram(a);q.re=a},sc:function(a,b){q.vertexAttrib1f(a,b)},rc:function(a,b){q.vertexAttrib2f(a,G[b>>2],G[b+4>>2])},qc:function(a,b){q.vertexAttrib3f(a,G[b>>2],G[b+4>>2],G[b+8>>2])},pc:function(a,b){q.vertexAttrib4f(a,G[b>>2],G[b+4>>2],G[b+8>>2],G[b+12>>2])},Yb:function(a,b){q.vertexAttribDivisor(a,
b)},Xb:function(a,b,c,d,e){q.vertexAttribIPointer(a,b,c,d,e)},oc:function(a,b,c,d,e,f){q.vertexAttribPointer(a,b,c,!!d,e,f)},nc:function(a,b,c,d){q.viewport(a,b,c,d)},$a:function(a,b,c,d){q.waitSync(Ac[a],b,(c>>>0)+4294967296*d)},u:function(a){var b=z.length;a>>>=0;if(2147483648<a)return!1;for(var c=1;4>=c;c*=2){var d=b*(1+.2/c);d=Math.min(d,a+100663296);d=Math.max(a,d);0<d%65536&&(d+=65536-d%65536);a:{try{ya.grow(Math.min(2147483648,d)-Oa.byteLength+65535>>>16);Ra();var e=1;break a}catch(f){}e=void 0}if(e)return!0}return!1},
qb:function(a,b){var c=0;Qc().forEach(function(d,e){var f=b+c;e=C[a+4*e>>2]=f;for(f=0;f<d.length;++f)Pa[e++>>0]=d.charCodeAt(f);Pa[e>>0]=0;c+=d.length+1});return 0},rb:function(a,b){var c=Qc();C[a>>2]=c.length;var d=0;c.forEach(function(e){d+=e.length+1});C[b>>2]=d;return 0},tb:function(){return 0},eb:function(){},sb:function(a,b,c,d){for(var e=0,f=0;f<c;f++){var g=C[b>>2],h=C[b+4>>2];b+=8;for(var n=0;n<h;n++){var p=z[g+n],r=Sc[a];0===p||10===p?((1===a?ua:va)(Ba(r,0)),r.length=0):r.push(p)}e+=h}C[d>>
2]=e;return 0},b:function(){return wa},db:function(a,b){q.bindFramebuffer(a,vc[b])},w:function(a,b){Hc(a,b)},n:ed,h:fd,o:gd,r:hd,hb:jd,ib:kd,q:ld,j:md,d:nd,p:od,i:pd,kb:qd,gb:rd,lb:sd,c:function(a){wa=a},ob:function(a,b,c,d){return Yc(a,b,c,d)}};
(function(){function a(e){k.asm=e.exports;ya=k.asm.Hc;Ra();Sa=k.asm.Rc;Ua.unshift(k.asm.Ic);Xa--;k.monitorRunDependencies&&k.monitorRunDependencies(Xa);0==Xa&&(null!==Ya&&(clearInterval(Ya),Ya=null),Za&&(e=Za,Za=null,e()))}function b(e){a(e.instance)}function c(e){return cb().then(function(f){return WebAssembly.instantiate(f,d)}).then(function(f){return f}).then(e,function(f){va("failed to asynchronously prepare wasm: "+f);ta(f)})}var d={a:td};Xa++;k.monitorRunDependencies&&k.monitorRunDependencies(Xa);
if(k.instantiateWasm)try{return k.instantiateWasm(d,a)}catch(e){return va("Module.instantiateWasm callback failed with error: "+e),!1}(function(){return xa||"function"!==typeof WebAssembly.instantiateStreaming||$a()||H.startsWith("file://")||"function"!==typeof fetch?c(b):fetch(H,{credentials:"same-origin"}).then(function(e){return WebAssembly.instantiateStreaming(e,d).then(b,function(f){va("wasm streaming compile failed: "+f);va("falling back to ArrayBuffer instantiation");return c(b)})})})().catch(ca);
return{}})();k.___wasm_call_ctors=function(){return(k.___wasm_call_ctors=k.asm.Ic).apply(null,arguments)};var Jc=k._malloc=function(){return(Jc=k._malloc=k.asm.Jc).apply(null,arguments)},Xb=k._free=function(){return(Xb=k._free=k.asm.Kc).apply(null,arguments)},dd=k.___errno_location=function(){return(dd=k.___errno_location=k.asm.Lc).apply(null,arguments)},Wb=k.___getTypeName=function(){return(Wb=k.___getTypeName=k.asm.Mc).apply(null,arguments)};
k.___embind_register_native_and_builtin_types=function(){return(k.___embind_register_native_and_builtin_types=k.asm.Nc).apply(null,arguments)};var X=k.stackSave=function(){return(X=k.stackSave=k.asm.Oc).apply(null,arguments)},Y=k.stackRestore=function(){return(Y=k.stackRestore=k.asm.Pc).apply(null,arguments)},Z=k._setThrew=function(){return(Z=k._setThrew=k.asm.Qc).apply(null,arguments)};k.dynCall_iiiij=function(){return(k.dynCall_iiiij=k.asm.Sc).apply(null,arguments)};
k.dynCall_viiij=function(){return(k.dynCall_viiij=k.asm.Tc).apply(null,arguments)};k.dynCall_iiij=function(){return(k.dynCall_iiij=k.asm.Uc).apply(null,arguments)};k.dynCall_viij=function(){return(k.dynCall_viij=k.asm.Vc).apply(null,arguments)};k.dynCall_jiiiiji=function(){return(k.dynCall_jiiiiji=k.asm.Wc).apply(null,arguments)};k.dynCall_jiiiiii=function(){return(k.dynCall_jiiiiii=k.asm.Xc).apply(null,arguments)};k.dynCall_viiiiij=function(){return(k.dynCall_viiiiij=k.asm.Yc).apply(null,arguments)};
k.dynCall_jii=function(){return(k.dynCall_jii=k.asm.Zc).apply(null,arguments)};k.dynCall_viji=function(){return(k.dynCall_viji=k.asm._c).apply(null,arguments)};k.dynCall_ji=function(){return(k.dynCall_ji=k.asm.$c).apply(null,arguments)};k.dynCall_iij=function(){return(k.dynCall_iij=k.asm.ad).apply(null,arguments)};k.dynCall_vij=function(){return(k.dynCall_vij=k.asm.bd).apply(null,arguments)};k.dynCall_iijj=function(){return(k.dynCall_iijj=k.asm.cd).apply(null,arguments)};
k.dynCall_jiji=function(){return(k.dynCall_jiji=k.asm.dd).apply(null,arguments)};k.dynCall_iiiiij=function(){return(k.dynCall_iiiiij=k.asm.ed).apply(null,arguments)};k.dynCall_iiiiijj=function(){return(k.dynCall_iiiiijj=k.asm.fd).apply(null,arguments)};k.dynCall_iiiiiijj=function(){return(k.dynCall_iiiiiijj=k.asm.gd).apply(null,arguments)};k.dynCall_viijii=function(){return(k.dynCall_viijii=k.asm.hd).apply(null,arguments)};
function nd(a,b,c){var d=X();try{K(a)(b,c)}catch(e){Y(d);if(e!==e+0&&"longjmp"!==e)throw e;Z(1,0)}}function gd(a,b,c,d){var e=X();try{return K(a)(b,c,d)}catch(f){Y(e);if(f!==f+0&&"longjmp"!==f)throw f;Z(1,0)}}function pd(a,b,c,d,e){var f=X();try{K(a)(b,c,d,e)}catch(g){Y(f);if(g!==g+0&&"longjmp"!==g)throw g;Z(1,0)}}function md(a,b){var c=X();try{K(a)(b)}catch(d){Y(c);if(d!==d+0&&"longjmp"!==d)throw d;Z(1,0)}}
function fd(a,b,c){var d=X();try{return K(a)(b,c)}catch(e){Y(d);if(e!==e+0&&"longjmp"!==e)throw e;Z(1,0)}}function hd(a,b,c,d,e){var f=X();try{return K(a)(b,c,d,e)}catch(g){Y(f);if(g!==g+0&&"longjmp"!==g)throw g;Z(1,0)}}function ed(a,b){var c=X();try{return K(a)(b)}catch(d){Y(c);if(d!==d+0&&"longjmp"!==d)throw d;Z(1,0)}}function ld(a){var b=X();try{K(a)()}catch(c){Y(b);if(c!==c+0&&"longjmp"!==c)throw c;Z(1,0)}}
function sd(a,b,c,d,e,f,g,h,n){var p=X();try{K(a)(b,c,d,e,f,g,h,n)}catch(r){Y(p);if(r!==r+0&&"longjmp"!==r)throw r;Z(1,0)}}function od(a,b,c,d){var e=X();try{K(a)(b,c,d)}catch(f){Y(e);if(f!==f+0&&"longjmp"!==f)throw f;Z(1,0)}}function qd(a,b,c,d,e,f){var g=X();try{K(a)(b,c,d,e,f)}catch(h){Y(g);if(h!==h+0&&"longjmp"!==h)throw h;Z(1,0)}}function kd(a,b,c,d,e,f,g){var h=X();try{return K(a)(b,c,d,e,f,g)}catch(n){Y(h);if(n!==n+0&&"longjmp"!==n)throw n;Z(1,0)}}
function jd(a,b,c,d,e,f){var g=X();try{return K(a)(b,c,d,e,f)}catch(h){Y(g);if(h!==h+0&&"longjmp"!==h)throw h;Z(1,0)}}function rd(a,b,c,d,e,f,g){var h=X();try{K(a)(b,c,d,e,f,g)}catch(n){Y(h);if(n!==n+0&&"longjmp"!==n)throw n;Z(1,0)}}var ud;Za=function vd(){ud||wd();ud||(Za=vd)};
function wd(){function a(){if(!ud&&(ud=!0,k.calledRun=!0,!za)){db(Ua);aa(k);if(k.onRuntimeInitialized)k.onRuntimeInitialized();if(k.postRun)for("function"==typeof k.postRun&&(k.postRun=[k.postRun]);k.postRun.length;){var b=k.postRun.shift();Va.unshift(b)}db(Va)}}if(!(0<Xa)){if(k.preRun)for("function"==typeof k.preRun&&(k.preRun=[k.preRun]);k.preRun.length;)Wa();db(Ta);0<Xa||(k.setStatus?(k.setStatus("Running..."),setTimeout(function(){setTimeout(function(){k.setStatus("")},1);a()},1)):a())}}
k.run=wd;if(k.preInit)for("function"==typeof k.preInit&&(k.preInit=[k.preInit]);0<k.preInit.length;)k.preInit.pop()();wd();


  return Rive.ready
}
);
})();
export default Rive;